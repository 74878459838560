import React, { MouseEventHandler, useState } from 'react';
import './searchBox.scss';
import SearchInput from '@modules/search/searchInput';

interface IProps {
  q?: string
  placeholder?: string
  click?: MouseEventHandler<HTMLDivElement>
}

const SearchBox = (props?: IProps) => {
  const { q: query } = props || {}
  const [visible, setVisible] = useState(false)

  const onClick = (e: any) => {
    e.preventDefault()
    setVisible(true)
  }
  return (
    <>
      <div className='m-search' onClick={onClick}>
        <a
          href={query ? `/search/?q=${query}` : '/search/'}
          className={query ? 'm-search-box value-style' : 'm-search-box'}>
          {query || `Search for any product or brand`}
        </a>
      </div>
      <SearchInput q={query} visible={visible} setVisible={setVisible} />
    </>
  )
}

export default SearchBox