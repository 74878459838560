// extracted by mini-css-extract-plugin
export var cp = "footer_m-module--cp--862b3";
export var fM = "footer_m-module--f-m--03dbc";
export var fMContent = "footer_m-module--f-m-content--1c40e";
export var fMContentShow = "footer_m-module--f-m-content-show--fd0d6";
export var fMT = "footer_m-module--f-m-t--16cbb";
export var fPcMain = "footer_m-module--f-pc-main--ab1b5";
export var fmProductHunt = "footer_m-module--fm-product-hunt--1414b";
export var footerM = "footer_m-module--footer-m--3b47f";
export var footerPc = "footer_m-module--footer-pc--14e88";
export var iconb = "footer_m-module--iconb--c324f";
export var minWidthLimit = "footer_m-module--min-width-limit--0ff9f";
export var pEco = "footer_m-module--p-eco--8076c";
export var pOutlets = "footer_m-module--p-outlets--45350";
export var turnDown = "footer_m-module--turn-down--f4f31";